.BagSelector {
    display: inline-block;
    height: 100px;
    width: 100px;
    cursor: pointer;
    position: relative;
    vertical-align: middle;
    margin: 0px 5px;
    border: 5px solid #fff;
    transition: border .3s ease;
    img {
        width: 90px;
        height: 90px;
    }
    .bag-label {
        position: absolute;
        bottom: 0;
        width: 100%;
        text-align: center;
        font-size: 16px;
        line-height: 1.7;
        background: rgba(0, 0, 0, 0.6);
        color: #fff;
    }
    &:first-of-type {
        margin-left: 0;
    }
    &:last-of-type {
        margin-right: 0;
    }
    &.selected {
        border: 5px solid #00aec1;
    }
}

@media screen and (max-width: 720px) {
    .BagSelector {
        height: 80px;
        width: 80px;
        img {
            width: 70px;
            height: 70px;
        }
        .bag-label {
            font-size: 14px;
        }
    }
}