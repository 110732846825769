.RankingTable {
    width: 100%;
    .ant-table-thead {
        tr {
            th {
                background: #d6de23;
                vertical-align: middle;
            }
        }
        .green-label:before {
            display: inline-block;
            content: "";
            background: #00aec1;
            height: 10px;
            width: 10px;
            vertical-align: middle;
            margin-right: 5px;
        }
        .red-label:before {
            display: inline-block;
            content: "";
            background: #f05a28;
            height: 10px;
            width: 10px;
            vertical-align: middle;
            margin-right: 5px;
        }
    }
}