.LoginPage {
    width: 100%;
    padding: 0 30px;
    h2 {
        font-size: 24px;
        color: #f16536;
        text-align: center;
    }
    h3 {
        font-size: 24px;
        color: #f16536;
        margin: 20px 0px;
    }
    .selection-row {
        margin: 30px 0px;
        .selection-options-row {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
    .start-game-row {
        margin-top: 60px;
        text-align: center;
        button {
            font-size: 32px;
            background: #1c3d73;
            padding: 30px 100px;
        }
    }
    .password-section {
        display: flex;
        justify-content: space-between;
        align-items: center;
        input {
            height: 90px;
            width: 55%;
            font-size: 24px;
            background: #1c3d73;
            color: #fff;
            border-radius: 30px;
            padding: 0 50px;
            outline: 0;
            border: 0;
            &::placeholder {
                color: #fff;
            }
        }
        button {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 30%;
            height: 90px;
            font-size: 24px;
            background: #1c3d73;
            padding: 20px 15px;
            color: #fff;
            transition: opacity .3s, background .3s;
            img {
                position: absolute;
                height: 24px;
                right: 30px;
            }
        }
    }
}