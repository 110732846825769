.Footer {
    position: relative;
    width: 100%;
    .footer-section {
        position: relative;
        &:after {
            content: '';
            display: block;
            clear: both;
        }
    }
    .footer-section-1 {
        z-index: 2;
        height: 180px;
        overflow: hidden;
        background: url('./footer-bg-r.png') right bottom no-repeat, url('./footer-bg-l.png') left top no-repeat;
        background-size: contain, contain;
        .slogan {
            position: absolute;
            left: 8%;
            bottom: 15px;
            z-index: 2;
            img {
                height: 100px;
            }
        }
        .plastic-bag-wrapper {
            left: 0;
            right: 0;
            margin-top: -90px;
            .plastic-bag {
                position: absolute;
                top: 30%;
                left: 0;
                height: 60px;
                z-index: 1;
            }
            .plastic-bag-1 {
                top: 25%;
                animation: movingRight 4.5s linear infinite;
            }
            .plastic-bag-2 {
                animation: movingRight 5.5s linear infinite;
            }
            .plastic-bag-3 {
                top: 40%;
                animation: movingRight 6.5s linear infinite;
            }
            .plastic-bag-4 {
                top: 10%;
                animation: movingRight 7.5s linear infinite;
            }
            .plastic-bag-5 {
                animation: movingRight 8.5s linear infinite;
            }
        }
    }
    .footer-section-2 {
        background: #1c3d74;
        color: #fff;
        z-index: 5;
        padding: 7px 0;
        font-size: .8em;
        a {
            color: #fff;
            text-decoration: none;
            &:hover,
            &:focus {
                color: #ffc10e;
            }
        }
        .footer-section-2-wrapper {
            margin: auto;
            width: 100%;
            max-width: 1200px;
            padding: 0 15px;
            text-align: center;
        }
    }
    .footer-section-3 {
        text-align: center;
        font-size: 10px;
        line-height: 1.1;
        background: #e2f2f0;
        color: #b3b3b3;
        z-index: 5;
        padding: 5px 15px;
    }
}

@keyframes movingRight {
    0% {
        opacity: 0;
        left: 0%;
    }
    25% {
        opacity: 1;
        left: 25%;
    }
    75% {
        opacity: 1;
        left: 70%;
    }
    90% {
        opacity: 1;
        left: 79%;
    }
    100% {
        opacity: 0;
        left: 80%;
    }
}