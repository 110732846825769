.WebInterface {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    justify-content: space-between;
    .container {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px 15px;
    }
}