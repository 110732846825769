.Header {
    position: relative;
    z-index: 20;
    padding: 0px 15px;
    .navbar {
        width: 100%;
        max-width: 1260px;
        position: relative;
        margin: 0 auto;
        padding-top: 20px;
        .navbar-wrapper {
            position: relative;
            .logo-gp {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0;
                margin-bottom: 0;
                .main {
                    height: 100px;
                    margin: 0 30px;
                }
                .sub {
                    &:first-of-type {
                        margin-top: 10px;
                    }
                    height: 65px;
                }
            }
            .btn-section {
                display: flex;
                justify-content: space-between;
                .left-section {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    .back-btn {
                        margin-right: auto;
                        padding: 10px 20px;
                        box-shadow: 0 5px 0 #081935, 0 5px 3px rgba(0, 0, 0, .4);
                        .anticon {
                            margin-right: 10px;
                        }
                    }
                }
                .right-section {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    .class-label {
                        color: #1c3d74;
                        font-size: 1em;
                        margin-right: 10px;
                        line-height: 51px;
                    }
                    .login-btn {
                        background-color: #e3eda4;
                        border-color: #e3eda4;
                        color: #1c3d74;
                        box-shadow: 0 5px 0 #c9dc4f, 0 5px 3px rgba(0, 0, 0, .4);
                        margin-bottom: 5px;
                        padding: 13px 20px 10px 20px;
                        font-size: 1.2em;
                        border-radius: 1000px;
                        transition: all .3s;
                        cursor: pointer;
                        &:hover {
                            background-color: #ecf3c2;
                            border-color: #ecf3c2;
                            opacity: .9;
                        }
                    }
                }
            }
            .language-btn-gp {
                position: absolute;
                top: 0;
                right: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                .language-btn {
                    background: #aaa;
                    height: 40px;
                    width: 40px;
                    line-height: 40px;
                    font-size: 20px;
                    text-align: center;
                    color: #fff;
                    border-radius: 50%;
                    cursor: pointer;
                    margin: 10px 5px;
                    &.selected {
                        background: #1c3d74;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 720px) {
    .Header {
        font-size: 14px;
        .navbar {
            .navbar-wrapper {
                .main-logo {
                    img {
                        height: 120px;
                    }
                }
                .right-section {
                    .login-btn {
                        font-size: 1.1em;
                    }
                }
            }
        }
    }
}