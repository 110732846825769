.SelectorBox {
    display: inline-block;
    font-size: 32px;
    width: 115px;
    height: 120px;
    line-height: 120px;
    background: #00aec1;
    border-radius: 30px;
    text-align: center;
    cursor: pointer;
    transition: opacity .3s;
    &:hover {
        opacity: 0.7;
    }
    &:active,
    &.selected {
        opacity: 1;
        border: 5px solid #1c3d74;
        line-height: 110px;
    }
}

@media screen and (max-width: 720px) {
    .SelectorBox {
        font-size: 24px;
        width: 95px;
        height: 100px;
        line-height: 100px;
        &.selected {
            line-height: 90px;
        }
    }
}