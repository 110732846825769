.StudentHelperPanelPage {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding: 15px 0px;
    .upper-section {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-bottom: 30px;
        .profile-section {
            flex: 3;
            text-align: center;
            .profile-pic {
                border: 7px solid #1c3d74;
                border-radius: 36px;
            }
            p {
                font-size: 24px;
                margin-top: 10px;
            }
        }
        .bag-store-section {
            flex: 7;
            text-align: center;
            .store-title {
                background: #d6de23;
                color: #1c3d73;
                font-size: 24px;
                font-weight: bold;
                padding: 5px 0;
                border: 6px solid #1c3d73;
            }
            .store-interface {
                position: relative;
                background: #e2f2f0;
                border-bottom: 15px solid #f05a28;
                padding: 70px 0px 0px 0px;
                .store-ad {
                    position: absolute;
                    height: 110px;
                    left: -30px;
                    bottom: -15px;
                }
                .store-header {
                    position: absolute;
                    top: 0;
                    background: url('./store-header.svg') bottom center repeat-x;
                    height: 50px;
                    width: 100%;
                }
                form {
                    .form-item {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-bottom: 5px;
                        padding: 10px 30px;
                        font-size: 18px;
                        .form-label {
                            // display: inline-block;
                            // width: 20%;
                            flex: 2;
                            text-align: left;
                            vertical-align: middle;
                        }
                        .form-input {
                            // display: inline-block;
                            // width: 80%;
                            flex: 8;
                            text-align: left;
                            .bags-container {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            }
                        }
                        .bw-btn {
                            background: #00aec1;
                            padding: 10px 100px;
                            font-size: 24px;
                        }
                    }
                }
            }
        }
    }
    .lower-section {
        width: 100%;
        .ant-tabs-bar {
            margin: 0;
            .ant-tabs-nav-container {
                background: #e2f2f0;
                color: #1c3d74;
                text-align: center;
                .ant-tabs-tab {
                    color: #1c3d74;
                    font-size: 1.5em;
                }
                .ant-tabs-ink-bar {
                    height: 5px;
                }
            }
        }
        .ant-table {
            .icon-btn {
                cursor: pointer;
            }
            .remove-btn {
                color: #f16536;
            }
        }
        .ant-table-thead {
            tr {
                th {
                    background: #d6de23;
                }
            }
        }
        .ant-table-footer {
            background: #d6de23;
            .table-footer {
                font-weight: bold;
                text-align: center;
            }
        }
    }
}

@media screen and (max-width: 720px) {
    .StudentHelperPanelPage {
        padding: 0;
        .upper-section {
            flex-direction: column;
            margin-bottom: 10px;
            .profile-section {
                margin-bottom: 15px;
                .profile-pic {
                    width: 130px;
                }
                p {
                    font-size: 16px;
                }
            }
            .bag-store-section {
                width: 90%;
                .store-title {
                    font-size: 18px;
                }
                .store-interface {
                    padding: 40px 0px 0px 0px;
                    border-bottom: 10px solid #f05a28;
                    .store-header {
                        height: 40px;
                    }
                    form {
                        .form-item {
                            font-size: 16px;
                            margin-bottom: 0;
                            padding: 5px 30px;
                            .bw-btn {
                                background: #00aec1;
                                padding: 10px 80px;
                                font-size: 18px;
                            }
                        }
                    }
                    .store-ad {
                        height: 90px;
                        left: -28px;
                        bottom: -10px;
                    }
                }
            }
        }
        .lower-section {
            .ant-tabs-bar {
                .ant-tabs-nav-container {
                    .ant-tabs-tab {
                        font-size: 1.2em;
                    }
                }
            }
            .ant-table-footer {
                padding: 10px;
            }
        }

    }
}