.WebLandingPage {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    .banner-section {
        position: relative;
        width: 100%;
        margin: 15px 0;
        border-radius: 30px;
        overflow: hidden;
        img {
            width: 100%;
            border-radius: 30px;
        }
        .clickable-img {
            cursor: pointer;
        }
    }
    .highlight-section {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-bottom: 15px;
        .highlight-photo-section {
            width: 50%;
            height: 342px;
            background: rgba(255,255,255,0.4);
            border-radius: 30px;
            margin-right: 15px;
            overflow: hidden;
            .slick-active {
                img {
                  -webkit-transition: all 12s ease;
                  -moz-transition: all 12s ease;
                  -ms-transition: all 12s ease;
                  transition: all 12s ease;
                    transform: scale(1.4);
                }
            }
            .highlight-photo {
                position: relative;
                border-radius: 30px;
                width: 100%;
                text-align: center;
                img {
                    display: block;
                    margin-left: auto;
                    margin-right: auto;
                    width: auto;
                    height: 342px;
                }

                .description-overlay {
                    position: absolute;
                    top: 0;
                    font-size: 18px;
                    text-align: center;
                    background: rgba(0, 0, 0, 0.3);
                    color: #fff;
                    width: 100%;
                    z-index: 100;
                    padding: 5px 0px;
                }
            }
        }
        .highlight-video-section {
            width: 50%;
            height: 342px;
            border-radius: 30px;
            overflow: hidden;
        }
    }
    .ant-table-wrapper {
        border-radius: 30px;
        overflow: hidden;
        margin-bottom: 15px;
    }
}

@media screen and (max-width: 720px) {
    .WebLandingPage {
        .banner-section {
            margin: 5px 0;
        }
        .highlight-section {
            margin-bottom: 5px;
            .highlight-video-section {
              height: 250px;
            }
          .highlight-photo-section {
            width: 50%;
            height: 250px;
            .highlight-photo {
              img {
                height: 250px;
              }
            }
          }
        }

    }
}